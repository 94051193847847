iframe {
  border: none;
}

.App {
  text-align: center;
}

.App-logo {
  border-bottom: 1px solid rgb(195, 195, 195);
  margin-top: 0;
  padding: 10px;
  height: 29px;
  pointer-events: none;
  margin-bottom: 7px;
  margin-left: -120px;
  background-color: #060F31;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #7af8a1;
}
.bar-item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: white;
}
.pointer {
  cursor: pointer;
}
.bars {
  display: flex;
  column-gap: 1px;
  flex: 1;
}
.font-smaller {
  font-size: smaller;
}
.bg-light-grey {
  background-color: var(--color-light-grey);
}

.tooltip {
  position: relative;
}

.tooltip .bottom {
  min-width: 120px;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px 10px;
  color: #444444;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 11px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
}

.tooltip:hover .bottom {
  visibility: visible;
  opacity: 1;
}

.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -6px;
  width: 12px;
  height: 6px;
  overflow: hidden;
}

.tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

th {
  padding: 8px 0px 8px 0px;
  text-align: left;
  border-bottom: 1px solid #eee;
  background: #fff;
}
td {
  text-align: left;
  padding: 4px 0;
}
.dt-table tbody tr:nth-child(even) {
  background-color: #fafafa;
}

.responsible:hover {
  background-color: rgb(240, 240, 240);
}
.tox-tinymce-aux {
  z-index: 1000000 !important;
}

.mainContainer{
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  flex-flow: nowrap;
  position: relative;
  padding-right: 50px;
  width: 100%;
}
.mainContainerUseCase{
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  flex-flow: nowrap;
  position: relative;
  padding-right: 50px;
  justify-content: space-between;
  width: 100%;
}
.showFieldDropdown{
  cursor: pointer;
  width: 200px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.mainContainer .searchContainer{
  width: 30%;
  margin-bottom: 10px;
}
.mainContainerUseCase .searchContainer{
  width: 30%;
  margin-bottom: 10px;
}
.mainContainer .cockpitContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
}
.mainContainer .showFieldContainer {
  margin-left: 1%;
  margin-right: 1%;
}
.mainContainerUseCase .domainFieldContainer{
  margin-right: 1%;
}

@media only screen and (max-width:1200px) {
  .mainContainer{
    flex-flow: wrap;
  }
  .mainContainerUseCase{
    flex-flow: wrap;
  }
  .mainContainer .searchContainer{
    width: 100%;
    max-width: 500px;
    margin-right: 10px;
  }
  .mainContainerUseCase .searchContainer{
    width: 100%;
    max-width: 500px;
    margin-right: 10px;
  }
  .mainContainer .cockpitContainer{
    width: 100%;
    max-width: 500px;
    margin-right: 10px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .mainContainerUseCase .cockpitContainer{
    width: 100%;
    max-width: 500px;
    margin-right: 10px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .mainContainer .dropdownLabel{
    width: 90px;
  }
  .mainContainerUseCase .dropdownLabel {
    width: 115px;
  }
  .mainContainer .showFieldContainer{
    margin: 0px;
  }
}

.centerTemplateDropdown{
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 55%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.centerTemplateText{
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  padding-bottom: 100px;
  display: flex;
  text-align: center;
  position: absolute;
  left: 55%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.embeddingSidebarResizer-normal {
  position: absolute;
  width: 2px;
  top: 0px;
  left: -2px;
  bottom: 0px;
  cursor: col-resize;
  background-color: #fff;
  border-left: 1px solid #ccc;
}

.embeddingSidebarResizer-normal:hover {
  position: absolute;
  width: 2px;
  top: 0px;
  left: -2px;
  bottom: 0px;
  cursor: col-resize;
  background-color: #fff;
  border-left: 2px solid lightslategrey;
}

.embeddingSidebarResizer-resizing {
  position: absolute;
  width: 2px;
  top: 0px;
  left: -2px;
  bottom: 0px;
  background-color: #fff;
  border-left: 2px solid lightslategrey;
}

.embeddingSidebarResizer-collapsed {
  position: absolute;
  width: 1px;
  top: 0px;
  left: -2px;
  bottom: 0px;
  cursor: w-resize;
  background-color: #fff;
  border-left: 1px solid #ccc;
}

.embeddingSidebarResizer-collapsed:hover {
  position: absolute;
  width: 2px;
  border-left: 2px solid lightslategrey;
}

.dqcsidebarlogo {
  padding-left: 20px;
  transform: rotate(90deg);
  display: block;
  cursor: pointer;
}

.changedText {
  margin-bottom: 1%;
}

.selectionChange {
  margin-bottom: 5px;
  width: 100%;
  align-self: flex-start;
}
.originalCell{
  margin-bottom: 1%;
}
.selectionChange .changedText {
  box-shadow: none;
  border: 1px solid rgb(222 222 222);
  border-radius: 2px;
  background: rgb(255, 255, 255);
  cursor: text;
  height: inherit;
  overflow: auto;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(50, 49, 48);
  background-color: rgb(223 223 223 / 32%);
}
.fullDashboardSelection {
  margin-bottom: 5px;
  box-shadow: none;
  border: 1px solid rgb(222 222 222);
  border-radius: 2px;
  background: rgb(255, 255, 255);
  cursor: text;
  height: inherit;
  overflow: auto;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(50, 49, 48);
  background-color: rgb(223 223 223 / 32%);
  min-height: 60px;
}

.changedText del {
  color: red;
}

.changedText ins {
  color: green;
}

.remarkTile:hover {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.status-SOLVED {
  background-color: rgb(218, 225, 217);
}

.openSeparator {
  color: blue;
}
.openSeparator::before {
  background-color: blue;
}

.solvedSeparator {
  color: green;
}
.solvedSeparator::before {
  background-color: green;
}
.mce-edit-focus { outline: none !important; }

.mce-inlineEdit {
  padding-left: 10px !important;
}

.overflow-container {
  position: relative;
  overflow: hidden;
  height: 1.3em;
  width: 99%;
}

.open-closer {
  font-size: x-small;
  color: grey;
  text-decoration: underline;
}

.rich-text > * {
  margin: 0
}
